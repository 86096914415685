
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<div v-else class="ma-2">
<h1>{{$store.getters.language.data.salary_report.title_report}} {{selectedSalaryList.salary_list_month}} / {{selectedSalaryList.salary_list_year}}</h1>
			<!-- <form @submit.prevent="addSalary" autocomplete="off">
                    <v-layout row wrap>
        
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="employees" v-model="salary.employee_id" dense  filled outlined  item-text="employee_id"
                                    item-value="employee_id" :return-object="false" :label="$store.getters.language.data.employees.employee_id">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="salary_list" v-model="salary.salary_list_id" dense  filled outlined  item-text="salary_list_id"
                                    item-value="salary_list_id" :return-object="false" :label="$store.getters.language.data.salary_list.salary_list_id">
                                </v-select>
                        </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_base" type="text" :label="$store.getters.language.data.salary.salary_base" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_total" type="text" :label="$store.getters.language.data.salary.salary_total" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_gov_inss" type="text" :label="$store.getters.language.data.salary.salary_gov_inss" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_fines_total" type="text" :label="$store.getters.language.data.salary.salary_fines_total" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_iligal_days_amount" type="number" :label="$store.getters.language.data.salary.salary_iligal_days_amount" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_iligal_days_total" type="text" :label="$store.getters.language.data.salary.salary_iligal_days_total" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_extra_hours_amount" type="text" :label="$store.getters.language.data.salary.salary_extra_hours_amount" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_extra_hours_total" type="text" :label="$store.getters.language.data.salary.salary_extra_hours_total" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_total_commission" type="text" :label="$store.getters.language.data.salary.salary_total_commission" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_inss_amount" type="text" :label="$store.getters.language.data.salary.salary_inss_amount" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex>
                
                   <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="salary.salary_insert_date" type="datetime-local" :label="$store.getters.language.data.salary.salary_insert_date" dense
                            class="mx-1"  filled outlined >
                        </v-text-field>
                    </v-flex>
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" >{{$store.getters.language.data.salary.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form> -->
			<!-- btn to export to excel -->
            <h1>{{$store.getters.language.data.salary_report.total_salary}} : {{total.toLocaleString()}}</h1>
			<v-btn color="primary" @click="exportToExcel" class="mx-1">
				Export to Excel <v-icon>mdi-microsoft-excel</v-icon>
			</v-btn>
			<v-layout row wrap mt-5>
				<v-flex xs12>
					<v-text-field v-model="search" :label="$store.getters.language.data.extra.search" append-icon="mdi-magnify" outlined></v-text-field>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="salary_id">
								<template v-slot:[`item.salary_id`]="{ item }">
									<div>
										<!-- <v-btn icon :to="'/salary-list/'+item.salary_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn> -->
										<v-btn color="error" icon class="mx-1" @click="selectSalary(item)" disabled>
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template>
								<template v-slot:[`item.salary_insert_date`]="{ item }">
									<div>
										{{new Date(item.salary_insert_date).toLocaleString()}}
									</div>
								</template>
								<template v-slot:[`item.salary_base`]="{ item }">
									<div>
										{{item.salary_base.toLocaleString()}}
									</div>
								</template>
								<template v-slot:[`item.salary_total`]="{ item }">
									<div>
										{{item.salary_total.toLocaleString()}}
									</div>
								</template>
								<template v-slot:[`item.salary_gov_inss`]="{ item }">
									<div>
										{{item.salary_gov_inss.toLocaleString()}}
									</div>
								</template>
								<template v-slot:[`item.salary_fines_total`]="{ item }">
									<div>
										{{item.salary_fines_total.toLocaleString()}}
									</div>
								</template>

								<template v-slot:[`item.salary_iligal_days_total`]="{ item }">
									<div>
										{{item.salary_iligal_days_total.toLocaleString()}}
									</div>
								</template>
								<template v-slot:[`item.salary_extra_hours_total`]="{ item }">
									<div>
										{{item.salary_extra_hours_total.toLocaleString()}}
									</div>
								</template>
								<template v-slot:[`item.salary_inss_amount`]="{ item }">
									<div>
										{{item.salary_inss_amount.toLocaleString()}}
									</div>
								</template>
								<template v-slot:[`item.employee_driver_budget`]="{ item }">
									<div>
										{{item.employee_driver_budget.toLocaleString()}}
									</div>
								</template>
								<template v-slot:[`item.employee_transportation_budget`]="{ item }">
									<div>
										{{item.employee_transportation_budget.toLocaleString()}}
									</div>
								</template>
								<template v-slot:[`item.employee_phone_budget`]="{ item }">
									<div>
										{{item.employee_phone_budget.toLocaleString()}}
									</div>
								</template>
                                <template v-slot:[`item.salary_extra_days_total`]="{ item }">
                                    <div>
                                        {{item.salary_extra_days_total.toLocaleString()}}
                                    </div>
                                </template>
                                <template v-slot:[`item.salary_commission_total`]="{ item }">
                                    <div>
                                        {{item.salary_commission_total.toLocaleString()}}
                                    </div>
                                </template> 
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteSalaryList">{{$store.getters.language.data.salary.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout>
		</div>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.salary.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.salary.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteSalary(selected_salary.salary_id)">
						{{$store.getters.language.data.salary.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/salary.request.js'
	import salary_listRequest from '../../requests/salary_list.request.js'
	import * as XLSX from 'xlsx'

	export default {
		data() {
			return {
				salary: {},
				search: '',
				loading: true,
                total: 0,
				loading_btn: false,
				selected_rows: [],
                selectedSalaryList: {},
				id: '',
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_salary: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.employees.employee_name,
						align: 'start',
						sortable: true,
						value: 'employee_name',
					},
					// {
					// 	text: this.$store.getters.language.data.salary_list.salary_list_id,
					// 	align: 'start',
					// 	sortable: true,
					// 	value: 'salary_list_id',
					// },

					{
						text: this.$store.getters.language.data.salary.salary_base,
						align: 'start',
						sortable: true,
						value: 'salary_base',
					},
                    {
                        text: this.$store.getters.language.data.employees.employee_food_budget,
                        align: 'start',
                        sortable: true,
                        value: 'employee_food_budget',
                    },
					//    employee_phone_budget
					{
						text: this.$store.getters.language.data.employees.employee_phone_budget,
						align: 'start',
						sortable: true,
						value: 'employee_phone_budget',
					}
					,
					{
						text: this.$store.getters.language.data.employees.employee_transportation_budget,
						align: 'start',
						sortable: true,
						value: 'employee_transportation_budget',
					},
					{
						text: this.$store.getters.language.data.employees.employee_driver_budget,
						align: 'start',
						sortable: true,
						value: 'employee_driver_budget',
					},
					{
						text: this.$store.getters.language.data.salary.salary_gov_inss,
						align: 'start',
						sortable: true,
						value: 'salary_gov_inss',
					},

					{
						text: this.$store.getters.language.data.salary.salary_iligal_days_amount,
						align: 'start',
						sortable: true,
						value: 'salary_iligal_days_amount',
					},
					{
						text: this.$store.getters.language.data.salary.salary_iligal_days_total,
						align: 'start',
						sortable: true,
						value: 'salary_iligal_days_total',
					},
					{
						text: this.$store.getters.language.data.salary.salary_extra_hours_amount,
						align: 'start',
						sortable: true,
						value: 'salary_extra_hours_amount',
					},
					{
						text: this.$store.getters.language.data.salary.salary_extra_hours_total,
						align: 'start',
						sortable: true,
						value: 'salary_extra_hours_total',
					},
					// { 
					//     text: this.$store.getters.language.data.salary.salary_total_commission,
					//     align: 'start',
					//     sortable: true,
					//     value: 'salary_total_commission',
					// },
					{
						text: this.$store.getters.language.data.salary.salary_inss_amount,
						align: 'start',
						sortable: true,
						value: 'salary_inss_amount',
					},
					{
						text: this.$store.getters.language.data.salary.salary_fines_total,
						align: 'start',
						sortable: true,
						value: 'salary_fines_total',
					},
                    {
                        text: this.$store.getters.language.data.salary.salary_extra_days_amount,
                        align: 'start',
                        sortable: true,
                        value: 'salary_extra_days_amount',
                    },
                    {
                        text: this.$store.getters.language.data.salary.salary_extra_days_total,
                        align: 'start',
                        sortable: true,
                        value: 'salary_extra_days_total',
                    },
                    
                    {
                        text: this.$store.getters.language.data.salary.salary_commission_total,
                        align: 'start',
                        sortable: true,
                        value: 'salary_commission_total',
                    },
					{
						text: this.$store.getters.language.data.salary.salary_total,
						align: 'start',
						sortable: true,
						value: 'salary_total',
					},
					{
						text: this.$store.getters.language.data.salary.salary_insert_date,
						align: 'start',
						sortable: true,
						value: 'salary_insert_date',
					},
					{
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'salary_id',
					}
				],
			}
		},
		computed: {
			employees() {
				return this.$store.getters.employees_list
			},
			salary_list() {
				return this.$store.getters.salary_list_list
			},
		},
		mounted() {
			this.id = this.$route.params.id
			this.readSalary();
            this.readSalaryList(this.id);
		},
		methods: {
            readSalaryList(salary_list_id) {
                this.loading = true
                salary_listRequest.getOneSalaryList(salary_list_id).then(r => {
                    if (r.status == 200) {
                        this.selectedSalaryList = r.data.row
                        this.loading = false
                    } else {

                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Salary List',
                            color: 'error'
                        }
                    }
                })
                    .catch(e => {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read Salary List',
                            color: 'error'
                        }
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
			addSalary() {
				this.loading_btn = true
				requests.createSalary(this.salary).then(r => {
					if (r.status == 200) {
						this.salary = {}
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'Salary Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add Salary',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteSalary(salary_id) {
				requests.deleteSalary(salary_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.salary_id != salary_id
						})
						this.snackbar = {
							value: true,
							text: 'Salary Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},
			exportToExcel() {
				const allSalary = this.rows.flatMap(m => {
					return {
						'Employee Name': m.employee_name,
						//   'Salary List': m.salary_list_id,
						'Base Salary': m.salary_base,
						'Food Budget': m.employee_food_budget,
						'Transportation Budget': m.employee_transportation_budget,
						'Phone Budget': m.employee_phone_budget,
						'Driver Budget': m.employee_driver_budget,

						'Gov Inss': m.salary_gov_inss,
						'Inss Amount': m.salary_inss_amount,
						'Ilegal Days Amount': m.salary_iligal_days_amount,
						'Ilegal Days Total': m.salary_iligal_days_total,
						'Overtime hours Amount': m.salary_extra_hours_amount,
						'Overtime Total': m.salary_extra_hours_total,
						'Fines Total': m.salary_fines_total,
                        'Extra Days Amount': m.salary_extra_days_amount,
                        'Extra Days Total': m.salary_extra_days_total,
                        'Commission Total': m.salary_commission_total,
						'Total': m.salary_total,
						//   'Insert Date': new Date(m.salary_insert_date).toLocaleString(),
					}
				})
				const ws = XLSX.utils.json_to_sheet(allSalary);
				const wb = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(wb, ws, "Salary");
				XLSX.writeFile(wb, "Salary.xlsx");
			},
			deleteSalaryList() {
				let ids = this.selected_rows.map(m => m.salary_id)
				requests.deleteSalaryList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.salary_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' Salary Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readSalary() {
				this.loading = true
				requests.getSalaryByColumn('salary_list_id', this.id).then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
                        this.total = r.data.rows.reduce((a, b) => a + b.salary_total, 0)
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read Salary',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read Salary',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectSalary(i) {
				this.selected_salary = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    